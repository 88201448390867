var brj = {
    config: {
        url: '/',
        api: {
            post: {
                'get': '/api/post/get',
                like: '/api/post/like'
            }
        },
        post: {
            promoted: {
                visible: true,
                template: $('.posts-promoted-element-template').html()
            },
            normal: {
                template: $('.posts-element-template').html(),
                perPage: 16
            }
        },
        tag: {
            template: $('.tags-element-template').html()
        },
        paginate: {
            template: $('.paginate-template').html()
        }
    },
    dom: {
        $postsPromoted: $('.posts-promoted'),
        $body: $('body'),
        $html: $('html'),
        $loader: $('.loader')
    },
    init: function () {
        // Wstępny reload wpisów
        brj.reload(true, true);

        // Podpina eventy
        brj.setEvents();

        //
        brj.dom.$body.removeClass('loading');
    },
    setEvents: function () {
        // MainTags
        $('.tags-main .tags-main--element').on('click', function () {
            var $self = $(this);

            

            if (!$self.hasClass('active')) {
                $self.addClass('active').siblings().removeClass('active');

                //
                $('.filters-search').val('').trigger('chosen:updated');
                $('.filters-type').val('').trigger('chosen:updated');
                $('.tags .tags-element').removeClass('active');
                $('.paginate .paginate--element').removeClass('active');

                brj.reload(true, true);
                brj.urlRefresh();
            }

            return false;
        });

        // View
        var $filtersView = $('.filters-view');
        $filtersView.hide();
        $('.filters-view-layer a[href="#' + $filtersView.val() + '"]').addClass('active');

        $filtersView.on('change', function () {
            var $post = $('.posts');

            if ($('.filters-view').val() == 'row') {
                $post.removeClass('grid').addClass('row');
            } else {
                $post.removeClass('row').addClass('grid');
            }

            brj.urlRefresh();

        });

        $('.filters-view-layer a').on('click', function () {
            var $self = $(this);

            $filtersView.val($self.attr('href').replace('#', '')).trigger('change');
            $self.addClass('active').siblings('a').removeClass('active');
        });

        // Serach
        $('.filters-search').on('keyup', function () {
            //
            $('.paginate .paginate--element').removeClass('active');

            brj.reload(true);
            brj.urlRefresh();
        });

        // Type
        if ($('.filters-type option').length < 2) {
            //$('.filters-type').parent().hide();
        }

        $('.filters-type').on('change', function () {
            //
            $('.paginate .paginate--element').removeClass('active');

            brj.reload(true);
            brj.urlRefresh();
        });

        // Order
        $('.filters-order').on('change', function () {
            //
            $('.paginate .paginate--element').removeClass('active');

            brj.reload();
            brj.urlRefresh();
        });

        // Tag

        $('.tags').on('click', '.tags-element', function () {
            var $self = $(this);



            if ($self.hasClass('active')) {
                $self.removeClass('active');
             
            } else {
                $self.addClass('active').siblings().removeClass('active');
              
            }



            //
            $('.paginate .paginate--element').removeClass('active');

            brj.reload();
            brj.urlRefresh();

            return false;
        });

        // Paginacja
        var $paginate = $('.paginate');

        $paginate.on('click', '.paginate--element', function () {
            var $self = $(this);

            if ($self.hasClass('active')) {
                $self.removeClass('active');
            } else {
                $self.addClass('active').siblings().removeClass('active');
            }

            brj.reload(false, false);
            brj.urlRefresh();

            return false;
        });

        $paginate.on('click', '.paginate--next, .paginate--prev', function () {
            var page = $(this).find('a').attr('href').replace('#', '');

            //console.log(page);

            $paginate.find('.paginate--element:nth(' + (page - 1) + ')').trigger('click');

            return false;
        });

        // Cookie
        // Jeśli ktoś kiedyś zaakceptował to ukrywamy
        if ($.cookie('ngr-cookie-banner') == 'true') {
            $('.cookie-banner').hide();
        }
        else {
            brj.dom.$body.addClass('is-cookie');
        }

        // Po kliku ukrywamy i zapisujemy
        $('.cookie-banner .cookie-banner--close').on('click', function () {
            $.cookie('ngr-cookie-banner', true, {expires: 360});

            $('.cookie-banner').slideUp();
            brj.dom.$body.removeClass('is-cookie');
            return false;
        });


        // Rekomendacje
        brj.dom.$body.on('click', '.recomendation', function () {
            var $self = $(this);

            // Sprawdza czy user już lubi, jeśli tak nie pozwala jeszcze raz przesłać polubienia
            if ($self.hasClass('user-like')) {
                return false;
            }

            // Blokada kliku kliknięć
            $self.addClass('user-like');

            //
            var postId = $self.parents('.posts-element').data('id');

            $.ajax({
                url: brj.config.api.post.like,
                xhrFields: {withCredentials: true},
                data: {id: postId},
                success: function (data) {
                    $self.find('.recomendation--counter').text(data.like);
                }
            });

            return false;
        });

        // Share
        brj.dom.$body.on('click', '.js-share', function () {
            var $self = $(this);

            window.open($self.data('url'), '_blank', 'width=400,height=400');

            return false;
        });

        // Video w popupie
        brj.dom.$body.on('click', '.posts-element--link', function (e) {
            var $self = $(this);
            var data_video = $self.attr('data-video');
            if (data_video) {
                e.preventDefault();
                brj.popupVideo(data_video);
            }
        });


        brj.dom.$body.on('click', '.popup--close', function (e) {
            var $self = $(this);
            $self.parent().fadeOut(300, function () {
                $self.parent().remove();
            });
        });

    },
    getParameters: function (parameters) {
        parameters = parameters || [];
        var data = {
            tags: [],
            types: []
        };

        // Tag główny
        var activeMainTag = $('.tags-main .tags-main--element.active');

        if ($.inArray('mainTag', parameters) >= 0 && activeMainTag.length) {
            if (data.tags == undefined) {
                data.tags = [];
            }

            data.tags.push(activeMainTag.find('a').attr('href').replace('#', ''));
        }

        //
        var filtersView = $('.filters-view');

        if ($.inArray('view', parameters) >= 0 && filtersView.val() && filtersView.val() != 'grid') {
            data.view = filtersView.val();
        }

        //
        var filtersSearch = $('.filters-search');

        if ($.inArray('search', parameters) >= 0 && filtersSearch.val()) {
            data.search = filtersSearch.val();
        }

        //
        var filtersType = $('.filters-type');

        if ($.inArray('types', parameters) >= 0 && filtersType.val()) {
            data.types = [];

            data.types.push(filtersType.val());
        }

        //
        var filtersOrder = $('.filters-order');

        if ($.inArray('order', parameters) >= 0 && filtersOrder.val() && filtersOrder.val() != 'like') {
            data.order = filtersOrder.val();
        }

        // Tag
        var activeTag = $('.tags .tags-element.active');

        if ($.inArray('tag', parameters) >= 0) {

            if (activeTag.length) {
                data.tags.push(activeTag.find('a').attr('href').replace('#', ''));
            }

            // Jeśli user już ma już coś w get
            var defaultTags = $('.tags').data('active') || null;

            if (defaultTags != null) {
                data.tags = $.merge(data.tags, defaultTags);
            }
        }

        // Paginacja
        var activePaginate = $('.paginate .paginate--element.active');

        if ($.inArray('paginate', parameters) >= 0) {
            data.page = 1;

            if (activePaginate.length) {
                data.page = activePaginate.find('a').attr('href').replace('#', '');
            }

            // Jeśli user już ma już coś w get
            var defaultPage = $('.paginate').data('active') || null;

            if (defaultPage != null) {
                data.page = defaultPage;
            }
        }

        return data;
    },
    getData: function (tags, promoted) {
        var data = {
            tags: [],
            extends: []
        };

        // Jeśli mamy pobrać wpis promowany
        if (promoted) {
            data = $.extend(data, brj.getParameters(['mainTag']));

            // Pobieramy posty tylko promowane
            data.promoted = true;
        } else {
            data = $.extend(data, brj.getParameters(['mainTag', 'search', 'types', 'order', 'tag', 'paginate']));

            // Dodajemy limit na stronę dla stronicowania
            data.count = brj.config.post.normal.perPage;
        }

        // refresh normalnych tagów wymaga parametru w api
        if (tags) {
            data.extends.push('tags');
        }

        return data;
    },
    urlRefresh: function () {
        var parameters = brj.getParameters(['mainTag', 'search', 'types', 'order', 'tag', 'view', 'paginate']);

        // Wyjątek dla data.page ponieważ zawsze musi być w ajaxach ale nie zawsze w url'u
        if (parameters.page == 1) {
            delete parameters.page;
        }

        // Jeśli nie wszystkie wartości są standardowe - czyścimy url
        var url = !$.isEmptyObject(parameters) ? '?' + $.param(parameters) : '';

        if (url == '?') {
            url = '';
        }

        // Zmienia url
        window.history.pushState(null, '', brj.config.url + url);
    },
    reload: function (tags, promoted) {
        tags = tags || false;
        promoted = promoted || false;

        if (promoted && brj.config.post.promoted.visible) {
            brj.dom.$body.addClass('posts-promoted-loading');

            $.get(brj.config.api.post.get + '?' + $.param(brj.getData(tags, true)), function (data) {
                if (brj.dom.$postsPromoted.hasClass('slick-initialized')) {
                    brj.dom.$postsPromoted.slick('unslick');
                }

                brj.dom.$postsPromoted.find('.posts-element, .no-results').remove();
                brj.dom.$postsPromoted.append(Mustache.render(brj.config.post.promoted.template, data));

                var numberPromoted = brj.dom.$postsPromoted.find('.posts-element').length;

                if (numberPromoted > 2) {
                    brj.dom.$postsPromoted.removeClass('row').addClass('grid');
                    brj.dom.$postsPromoted.slick({
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        slide: '.posts-element',
                        arrow: true,
                        pauseOnHover: true,
                        autoplay: true,
                        autoplaySpeed: 4000,
                        responsive: [
                            {
                                breakpoint: 992,
                                settings: {
                                    slidesToShow: 2
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    arrows: true,
                                    draggable: true,
                                    centerMode: false,
                                    slidesToShow: 1,
                                    dots: true
                                }
                            }
                        ]
                    });
                } else if (numberPromoted == 2) {
                    brj.dom.$postsPromoted.removeClass('one-promoted').addClass('two-promoted');
                } else if (numberPromoted == 1) {
                    brj.dom.$postsPromoted.removeClass('grid').addClass('row').removeClass('two-promoted').addClass('one-promoted');
                }

                brj.dom.$body.removeClass('posts-promoted-loading');

                // Usuwanie kropek
                var ulDots = $('.slick-dots');
                if (ulDots.length) {
                    var countDots = $('.slick-dots').children().length;
                    if (countDots > 10) {
                        ulDots.remove();
                    }
                }
            });
        }

        brj.dom.$body.addClass('posts-loading');

        $.get(brj.config.api.post.get + '?' + $.param(brj.getData(tags, false)), function (data) {
            $('.posts').find('.posts-element, .no-results').remove();
            $('.posts').append(Mustache.render(brj.config.post.normal.template, data));

            // Jeśli nei wybrany jest żadny tag
            if (brj.getData(false, false).tags.length) {
                // zmiana tagów normalnych
                if (data.tags != undefined) {
                    var $tags = $('.tags');

                    $tags.html(Mustache.render(brj.config.tag.template, data));

                    if ($('.tags li').length) {
                        $('.tags').removeClass('hidden'); 
                        $('.filters').removeClass('upp');
                    } else {
                        $('.tags').addClass('hidden'); 
                        $('.filters').addClass('upp');
                    }

                    // Oznaczenie aktywnych - przy pierwszym wejściu
                    var activeTags = $tags.data('active') || '';

                    if (activeTags.length) {
                        // Ustawiamy falegę, by przy kolejnych zmianach stanu nie używać startowej aktywności
                        $tags.data('active', false);

                        $.each(activeTags, function (index, activeTag) {
                            $tags.find('[href="#' + activeTag + '"]').parent('li').addClass('active');
                        });
                    }
                }
            }
            // paginacja
            var $paginate = $('.paginate');
            data.paginate = [];

            // Oznaczenie aktywnych - przy pierwszym wejściu
            var activePage = $paginate.data('active') || null;

            if (activePage != null) {
                data.current_page = activePage;

                // Czyścimy
                $paginate.data('active', null);
            }

            // Tworzymy listę do paginacji
            for (var i = 1; i <= data.last_page; i++) {
                data.paginate.push({
                    number: i,
                    active: data.current_page == i
                });
            }

            // Poprzedni
            if (data.current_page > 1) {
                data.prev = data.current_page - 1;
            }

            // Następny
            if (data.current_page < data.last_page) {
                data.next = data.current_page + 1;
            }

            // Renderujemy paginację
            $paginate.html(Mustache.render(brj.config.paginate.template, data));

            brj.dom.$body.removeClass('posts-loading');
        });
    },
    popupVideo: function (link) {
        var $popup = $('<div>').addClass('popup popup--video');
        var $videoWrap = $('<div>').addClass('video-wrapper');
        var $close = $('<div>').addClass('popup--close');
            var $iframeVideo = $('<iframe>')
                .attr('src', link + '?autoplay=1&rel=0&title=0&byline=0&portrait=0')
                .attr('allowfullscreen', '')
                .attr('frameborder', "0")
                .attr('type', 'text/html');

        $videoWrap.append($iframeVideo);

        $popup.append($videoWrap);
        $popup.append($close);
        brj.dom.$body.append($popup);
    }
};

function range(start, count) {
    if (arguments.length == 1) {
        count = start;
        start = 0;
    }

    var foo = [];
    for (var i = 0; i < count; i++) {
        foo.push(start + i);
    }
    return foo;
}

$(function () {
    brj.init();

    // Mobile menu
    $('.nav-icon').click(function () {
        $(this).toggleClass('open');
        $('.tags-main').toggleClass('open');
    });

    // Sticky menu
    var $body = $('body');
    $(window).on('scroll', function () {
        if ($(this).scrollTop() > 56) {
            $body.addClass('header--sticky');
        } else {
            $body.removeClass('header--sticky');
        }
    });

    // Chosen init
    $('.select-chosen').chosen({
        width: '100%',
        disable_search: true
    });

    $(".tags-main a").on("touchend", function (event) {
        window.location.href = $(this).attr("href");
    });
    
    if ($('.tags li').length) {
        $('.tags').removeClass('hidden'); 
        $('.filters').removeClass('upp');
    } else {
        $('.tags').addClass('hidden'); 
        $('.filters').addClass('upp');
    }

});